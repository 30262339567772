<template>
	<nav class="nav d-print-none">
		<div class='container-fluid'> 
			<ul class="top-nav">
				<!-- empty for now -->
				<!--<li><a href='/dashboard' :class="{ 'text-success': $route.matched.some(({ name }) => name === 'dashboard') }"><i class="zwicon-line-chart"></i> Dashboard</a></li>-->
				<li><a href='/inventory' :class="{ 'text-success': $route.matched.some(({ name }) => name === 'inventory') }" v-if="getPermission('inventory', 'view')"><i class="zwicon-price-tag"></i> Inventory</a></li>
				<li><a href='/non-inventory' :class="{ 'text-success': $route.matched.some(({ name }) => name === 'non_inventory') }" v-if="getPermission('tickets', 'view')"><i class="zwicon-price-tag"></i> Non-inventory Items</a></li>
				<li><a href='/ticket' :class="{ 'text-success': $route.matched.some(({ name }) => name === 'ticket_list') }" v-if="getPermission('tickets', 'view')"><i class="zwicon-send"></i> Shipping Tickets</a></li>
				<li class='float-right'><a href='/users' :class="{ 'text-success': $route.matched.some(({ name }) => name === 'users') }" v-if="getPermission('users', 'view')"><i class="zwicon-users"></i> Users</a></li>
				<li class='float-right'><a href='/config' :class="{ 'text-success': $route.matched.some(({ name }) => name === 'config') }" v-if="getPermission('config', 'view')"><i class="zwicon-cog"></i> Config</a></li> 
			</ul>
		</div>
	</nav>
</template>

<style scoped lang="scss">
	.nav{
		background-color: rgba(0,0,0,.125);
		position: fixed;
		top: 72px;
		width: 100%;
		z-index: 99;
		transition: background-color 1000ms, box-shadow 1000ms, -webkit-box-shadow 1000ms;

		ul.top-nav{
			li{
				margin: 0.75rem 0.5rem 0.65rem;
				a{
					padding: 0.75rem 1rem 0.5rem;

					i.blink-me {
						animation: blinker 1s linear infinite;
						color: red; 
						font-size: 15px;
						text-decoration: none !important;
					}
				}
			}
		}
		&.nav--scrolled{
			background-color: #444;
		}
	}

	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
</style>

<script>
	import { mapGetters } from "vuex";

	import PermissionService from "@/common/permission.service";

	export default {
		name: 'Nav',
		computed: {
			...mapGetters(['user', 'election'])
		}, 
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			}
		}
	}
</script>