<template>
	<div class='loading-page'>
		<div class="page-loader__spinner">
			<svg viewBox="25 25 50 50">
				<circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
			</svg>
		</div> 
	</div>
</template>

<style>
	.loading-page{
		background-color: #CCC;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		z-index: 98;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
</style>

<script>
	export default {
		name: 'LoadingPage'
	}
</script>