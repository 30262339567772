<template>
	<div>
		<SwitchCompany v-if='show_switcher' @close='show_switcher = false;'/>
		<img class='d-none d-print-block mt-5 ml-4' style='width:250px' :src="user.current_company.logo_url">
		<header class="header d-print-none">
			<div class="logo d-none d-sm-inline-flex">
				<a href="/"><img style='' :src="user.current_company.logo_url"></a>
			</div>

			<ul class="top-nav" style='margin: 0 auto 0 0 !important; padding-left: 15px;'>
				<!-- empty for now -->
			</ul>

			<div class="d-none d-md-inline-block">
				<div class="user">
					<div class="user__info" @click='display_menu = !display_menu'>
						<i class="header-icon zwicon-user-circle"></i>
						<div>
							<div class="user__name">{{user.name}}</div>
							<div class="user__email">{{user.email}}</div>
						</div>
					</div>

					<div class="user-box dropdown-menu dropdown-menu--invert" v-if='display_menu' x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 65px, 0px); top: 8px; left: 0px; will-change: transform;" v-click-outside="close_menu">
						<a class="dropdown-item" href='#' @click.prevent="show_switcher = true;">Switch Company</a>
						<a class="dropdown-item" href="/settings">User Settings</a>
						<a class="dropdown-item" href='#' @click.prevent='logout'>Logout</a>
						<hr class='mb-1 mt-1' v-if='user.super_user' />
						<a class="dropdown-item admin-link" href="/admin" v-if='user.super_user'><i class="zwicon-lock"></i>Administrator</a>
					</div>
				</div>
			</div>
		</header>
	</div>
</template>

<style scoped>
	.list-group-item{
		padding: 0px;
	}
	.list-group-item a{
		padding: 10px;
	}
	.header-icon{
		font-size: 3rem;
		margin-right: 1rem;
	}
	.user-box{
		margin-top: -1rem;
		display: block;
	}
	.invert-image-color{
		filter: invert(1);
	}

	.invert-image-color img{
		filter: brightness(0);
	}
	.logo{
		min-width: auto !important;
		height: 65px !important;
	}
	.admin-link{
		color: #dc3545 !important;
	}
	.user__info{
		font-size: 0.9rem;
		padding: 0.5rem 1rem;
		margin-top: 1rem;
		border-radius: 2px;
	}
</style>

<script>
    
	import { mapGetters } from 'vuex';
	import store from "@/store/";

	import SwitchCompany from '@/components/Layout/Modals/SwitchCompany';  

    import { LOGOUT } from "@/store/actions.type";
    
	export default {
		name: 'Header',
		components: {
			SwitchCompany
		},
		data: () => {
			return {
				show_switcher: false,
				display_menu: false
			}
		},
		computed: {
			branded_header(){
				return {
					'backgroundColor': this.branding.header_color
				}
			},
			...mapGetters(['user', 'branding'])
		},
		methods: {
			close_menu(){
				this.display_menu = false;
			},
			/**
			 * Triggers a window resize event when clicked
			 * for plugins that needs to be redrawn
			 */
			resize: () => {
				// all IE friendly dispatchEvent
				var evt = document.createEvent('UIEvents');
				evt.initUIEvent('resize', true, false, window, 0);
				window.dispatchEvent(evt);
				// modern dispatchEvent way
				// window.dispatchEvent(new Event('resize'));
			},
			logout(){
				store.dispatch(LOGOUT)
				.then(() => {
					this.$router.push({ name: 'login', query: { time: Date.now() }, params: { success: "You have successfully signed out." }});
				});
			}
		}
	}
</script>