<template>
	<!-- Add Voter Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered" v-click-outside="close">
				<div class='modal-content' v-if='action_running'>
					<div class="modal-body">
						<h5 class='text-info'>Loading Company</h5>
						You will be taken to your selected company shortly.
					</div>
				</div>
				<div class="modal-content" v-if='!action_running'>
					<div class="modal-header">
						<h5 class="modal-title">Switch Company</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div>
					<div class="modal-body">
						<h5>Would you like to view a different company?</h5>
						Below is a list of all companies that your user has access to.

						<div class='row mt-4'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>View Company</label>
									<select class="form-control" v-model='view_company'>
										<option :value="election.company_id" v-for='election in user.companies' v-bind:key="election.company_id">{{election.name}}</option>
									</select>
									<i class="select-down zwicon-chevron-down display-4"></i>
								</div>
							</div>
						</div>

					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" @click='action'>Load</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Add Voter Modal -->
</template>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";

	import PermissionService from "@/common/permission.service";

	import { SWITCH_COMPANY } from "@/store/actions.type";

	import ErrorMessage from "@/components/General/ErrorMessage";

	export default {
		name: 'SwitchCompany',
		components: {
			ErrorMessage
		},
		props: {},
		data(){
			return {
				action_running: false,
				error: '',
				view_company: '',
				errors: {}
			};
		},
		mounted(){
			console.log(this.user)
			this.view_company = this.user.current_company.company_id;
		},
		computed: {
			...mapGetters(['user', 'election'])
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.action_running) return;
				this.$emit('close');
			},
			action(){
				if(this.action_running) return;
				this.action_running = true;
				store.dispatch(SWITCH_COMPANY, this.view_company).then(() => {
					location.reload();
				}).catch(message => {
					this.error = message;
					this.action_running = false;
				});
			}
		}
	}
</script>