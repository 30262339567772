<template>
	<footer class="container-fluid mb-4 mt-4">
		<div class="row">
		<div class='col-sm-6 footer-left text-center text-sm-left mb-4'>
			<img class="footer-logo d-print-none" src="/img/logo/eclipse-reverse-white-full.png">
			<img class="footer-logo d-none d-print-block" src="/img/logo/eclipse-logo.png">
		</div>
		<div class='col-sm-6 text-center text-sm-right mb-4'>
			<span style='color: #999'>Eclipse Inventory Management<br>Copyright &copy; 2022 Eclipse NDT Inc.</span>
		</div>
		</div>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex';

	
export default {
	name: 'Footer',
	computed: {
		year(){
			var d = new Date();
			return d.getFullYear();
		},
		...mapGetters([])
	}
}
</script>