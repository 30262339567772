<template>
	<main class="main">

		<!-- top navbar-->
		<Header />
		<Nav />

		<LoadingPage v-if='loading'/>
		
		<!-- Main section-->
		<section class="main-content container-fluid">
			<!-- Page content-->
			<router-view/>
		</section>

		<!-- Page footer-->
		<Footer />

	</main>
</template>

<style scoped>
	.main-content{
		margin-top: 120px;
		padding-top: 20px;
	}
</style>

<script>
	import { mapGetters } from "vuex";

	import Header from './Header';
	import Nav from './Nav';
	import Footer from './Footer';
	import LoadingPage from './LoadingPage';
    
	export default {
		name: 'Layout',
		components: {
			Header,
			Nav,
			Footer,
			LoadingPage
		},
		computed: {
			...mapGetters(['loading'])
		}
	}
</script>